import {
    TechRadarApi,
    TechRadarLoaderResponse,
  } from '@backstage-community/plugin-tech-radar';

  import { z } from 'zod';

export const WESCALE_TECH_RADARS = [
  {name: 'Global', pageTitle: 'Tech Radar Global de WeScale'},
  {name: 'Global (2023)', id: '2023', pageTitle: 'Tech Radar Global de WeScale (2023)'},
  {name: 'Green IT', id: 'green-it', pageTitle: 'Tech Radar du cercle Green IT'},
  {name: 'Conteneurs et Orchestration', id: 'containers-and-orchestration', pageTitle: 'Tech Radar du cercle Conteneurs et Orchestration'},
  {name: 'Cloud Native Dev', id: 'cloud-native-dev', pageTitle: 'Tech Radar du cercle Cloud Native'},
  {name: 'Sécurité', id: 'security', pageTitle: 'Tech Radar du cercle Sécurité',},
  {name: 'Cloud Providers', id: 'cloud-providers', pageTitle: 'Tech Radar du cercle Cloud Providers'},
]
  
  export class WeScaleTechRadar implements TechRadarApi {
    async load(id: string | undefined): Promise<TechRadarLoaderResponse> {

      const techRadarRepository = process.env.TECH_RADAR_URL || "https://apiwe.oxp.tooling.gitops.wescale.fr/api/tech-radar"
      const fileName = id ? `tech-radar-${id}.json` : 'tech-radar.json'
      const fileURL = [techRadarRepository, fileName].join('/')

      const techRadarSchema = z.object({
        title: z.string(),
        quadrants: z.array(z.object({
          id: z.string(),
          name: z.string()
        })),
        rings: z.array(z.object({
          id: z.string(),
          name: z.string(),
          color: z.string()
        })),
        entries: z.array(z.object({
          id: z.string(),
          title: z.string(),
          description: z.string().optional(),
          key: z.string(),
          url: z.string().optional(),
          quadrant: z.string(),
          timeline: z.array(z.object({
            moved: z.number(),
            ringId: z.string(),
            date: z.string(),
            description: z.string()
          }))
        }))
      })
      
      const data = await fetch(fileURL).then(res => res.json());
      const parsedData = techRadarSchema.parse(data)      
  
      // For example, this converts the timeline dates into date objects
      return {
        ...parsedData,
        entries: parsedData.entries.map(entry => ({
          ...entry,
          timeline: entry.timeline.map(timeline => ({
            ...timeline,
            date: new Date(timeline.date),
          })),
        })),
      };
    }
  }