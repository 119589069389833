import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  errorApiRef,
  googleAuthApiRef
} from '@backstage/core-plugin-api';
import {
  graphQlBrowseApiRef,
  GraphQLEndpoints,
} from '@backstage/plugin-graphiql';

import { WeScaleTechRadar } from './lib/WeScaleTechRadar';
import { techRadarApiRef } from '@backstage/plugin-tech-radar';


export const apis: AnyApiFactory[] = [
  createApiFactory(techRadarApiRef, new WeScaleTechRadar()),
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory({
    api: graphQlBrowseApiRef,
    deps: { errorApi: errorApiRef, googleAuthApi: googleAuthApiRef },
    factory: ({}) =>
      GraphQLEndpoints.from([
        // Use the .create function if all you need is a static URL and headers.
        GraphQLEndpoints.create({
          id: 'gitlab',
          title: 'GitLab',
          url: 'https://gitlab.com/api/graphql',
          // Optional extra headers
          headers: { Extra: 'Header' },
        }),
      ]),
  }),
];
